import MainTitle from 'components/common/admin/MainTitle';
import FormTextField from 'components/common/FormTextField';
import ModalTemplate from 'components/common/ModalTemplate';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as valid from 'utils/validation';
import '../mudmanage/Conf.scss';
import 'components/common/config/ConfigTemplate.scss';
import './Profile.scss';
import { useState } from 'react';
import { openNotification } from 'utils/commonFunctions';
import { checkPassword } from 'store/register';
import {
  changeEmailCancel,
  changeEmailResend,
  deleteUser,
  editPassword,
  requestAddUser,
  updateUser,
} from 'store/user';
import { LoadingOutlined } from '@ant-design/icons';
import { getLogout, setInitial } from 'store/login';
import axios from 'axios';
import { Option } from 'utils/commonValues';

export default function Profile(props: any) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const userData = useSelector((state: any) => state.user.user);
  const { errors, register, setValue, handleSubmit, control, clearErrors, setError } = useForm({
    mode: 'onChange',
  });

  const [openModal, setOpenModal] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>({
    type: '',
    content: '',
  });

  // 저장 버튼
  const onOk = async (formData: { [key: string]: any }) => {
    if (!loading) {
      setLoading(true);
      const params: { [key: string]: any } = {};
      const itemArray: Array<string> = ['userName', 'userEmail', 'userPhone', 'serviceName'];
      for (let i = 0; i < itemArray.length; i += 1) {
        if (formData[itemArray[i]] !== userData[itemArray[i]])
          params[itemArray[i]] = formData[itemArray[i]];
      }

      if (Object.keys(params).length > 0) {
        params.userNo = userData.userNo;
        const respObj: { [key: string]: any } = await dispatch(updateUser(params));
        // 중복 메일
        if (respObj?.data.error && respObj.data.error?.length > 0) {
          if (respObj.data.error[0].result === 'Duplicate User Exist') {
            setErrorMessage({
              type: 'userEmail',
              content: formatMessage({
                id: 'Email_9',
                defaultMessage: '이미 등록된 이메일입니다.',
              }),
            });
          } else if (respObj.data.error[0]?.errInfo === 'Restricted domain') {
            setErrorMessage({
              type: 'userEmail',
              content: formatMessage({
                id: 'Vali_61',
                defaultMessage: '프리메일은 이용할 수 없습니다.',
              }),
            });
          }
        } else {
          setValue('userEmail', userData.userEmail);
          openNotification(
            formatMessage({ id: 'MyPage_8', defaultMessage: '내 정보가 수정되었습니다.' }),
          );
        }
      }
      setLoading(false);
    }
  };

  // 취소 버튼
  const onCancel = () => {
    setValue('userName', userData.userName);
    setValue('userEmail', userData.userEmail);
    setValue('userPhone', userData.userPhone);
    setValue('serviceName', userData.serviceName);
  };

  // 비밀번호 변경
  const changePasswd = async (formData: any) => {
    if (!loading) {
      setLoading(true);
      try {
        const param = {
          userEmailId: userData.userEmail.split('@')[0],
          userPasswd: formData.userPasswd,
        };

        // 비밀번호 입력 제한 확인
        if (valid.RegExpUserPasswd.test(formData.userPasswd)) {
          const respObj: any = await dispatch(checkPassword(param));
          if (respObj?.status === 200) {
            // 규칙 실패
            if (
              respObj.data &&
              Array.isArray(respObj.data.list) &&
              respObj.data.list[0].error &&
              !!respObj.data.list[0].error.bit
            ) {
              const { error } = respObj.data.list[0];

              setErrorMessage({
                type: 'userPasswd',
                content: valid.password(error),
              });
            } // 규칙 성공
            else if (formData.userPasswd === formData.userPasswdCheck) {
              const params = {
                beforePasswd: formData.beforePassword,
                newPasswd: formData.userPasswd,
              };
              try {
                const respObj: any = await dispatch(editPassword(params));

                if (respObj?.status === 200) {
                  if (respObj.data && Array.isArray(respObj.data.list)) {
                    setErrorMessage({
                      type: 'success',
                      content: formatMessage({
                        id: 'Password_21',
                        defaultMessage: '비밀번호가 변경 되었습니다.',
                      }),
                    });
                    setOpenModal('');
                  } else if (Array.isArray(respObj.data.error)) {
                    if (respObj.data.error[0].errInfo === 'Passwd five time mismatched.') {
                      respObj.data.error[0].errInfo = formatMessage({
                        id: 'Password_24',
                        defaultMessage: '비밀번호가 5회 틀렸습니다.',
                      });
                    }
                    setErrorMessage({
                      type: 'beforePassword',
                      content: respObj.data.error[0].errInfo,
                    });
                  } else {
                    setErrorMessage({
                      type: 'beforePassword',
                      content: formatMessage({
                        id: 'Password_14',
                        defaultMessage: '비밀번호가 틀렸습니다.',
                      }),
                    });
                  }
                } else {
                  // 응답 오류
                }
              } catch (e) {
                console.log(e);
              }
            } else {
              setErrorMessage({
                type: 'userPasswdCheck',
                content: formatMessage({
                  id: 'Password_15',
                  defaultMessage: '비밀번호가 일치하지 않습니다.',
                }),
              });
            }
          }
        } else {
          setLoading(false);
          setErrorMessage({
            type: 'userPasswd',
            content: formatMessage(
              { id: 'Vali_59', defaultMessage: '영문, 숫자, 특수문자({character})만 가능합니다.' },
              { character: '!@#$%^&+=' },
            ),
          });
        }
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    }
  };

  // 탈퇴하기
  const onLeaveService = async (formData: any) => {
    try {
      setLoading(true);
      const params = { userPasswd: formData.userPasswd };

      await axios.delete('/api/v1/User', { data: params }).then((response: any) => {
        if (!response?.data?.error) {
          setLoading(false);
          setOpenModal('');
          getLogout();
          setInitial();
          window.location.reload();
        } else {
          setLoading(false);
          setError('userPasswd', {
            type: 'wrong',
            message: formatMessage({ id: 'Password_14', defaultMessage: '비밀번호가 틀렸습니다.' }),
          });
        }
      });
    } catch (error) {
      console.log('Profile onLeaveService', error);
    }
  };

  // 사용자 등록 의뢰
  const onRequestAddUser = async () => {
    try {
      setLoading(true);
      const params = {
        customerId: userData.customerId,
        storeId: userData.storeId,
        userEmail: userData.userEmail,
      };
      await dispatch(requestAddUser(params));
      setLoading(false);
      setOpenModal('');
    } catch (error) {
      console.log(error);
    }
  };

  // 폼 입력
  const onChangeForm = () => {
    if (errorMessage?.content) {
      setErrorMessage({
        type: '',
        content: '',
      });
    }
  };

  return (
    <div className="admin-basic-conf">
      {Option.customer === 'saxa' && (
        <button
          type="button"
          className={`side-btn add-user ${userData?.requestUserUpdate ? 'disabled' : ''} `}
          onClick={() => setOpenModal('addUser')}
        >
          {formatMessage({ id: 'User_25', defaultMessage: '사용자 등록 의뢰' })}
        </button>
      )}
      <button
        type="button"
        className="side-btn leave-service"
        onClick={() => setOpenModal('leave')}
      >
        {formatMessage({ id: 'MyPage_5', defaultMessage: '탈퇴하기' })}
      </button>
      <MainTitle
        className="small"
        title={formatMessage({ id: 'MyPage_4', defaultMessage: '내 정보 수정' })}
      />
      <div className="config-template">
        <div className="config-class">
          <div className="config-class-box">
            <form onChange={onChangeForm}>
              <div className="input-gap">
                <div className="config-row-option">
                  <div className="config-row-option-title">
                    <div className="config-class-title-wrap config-row-option-text">
                      {formatMessage({ id: 'Name_2', defaultMessage: '이름*' })}
                    </div>
                  </div>
                  <div className="config-row-option-value">
                    <FormTextField
                      name="userName"
                      error={errors.userName}
                      defaultValue={userData.userName}
                      register={register}
                      validation={{
                        validate: {
                          required: (value: any) => valid.required(value),
                          name: (value: any) => valid.name(value),
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="input-gap">
                <div className="config-row-option">
                  <div className="config-row-option-title">
                    <div className="config-class-title-wrap config-row-option-text">
                      {formatMessage({ id: 'Email_2', defaultMessage: '이메일*' })}
                    </div>
                  </div>
                  <div className="config-row-option-value">
                    <FormTextField
                      name="userEmail"
                      error={errors.userEmail}
                      defaultValue={userData.userEmail}
                      register={register}
                      validation={{
                        validate: {
                          required: (value: any) => valid.required(value),
                          email: (value: any) => valid.email(value),
                        },
                      }}
                      errorCheck={errorMessage.type === 'userEmail'}
                      errorText={errorMessage.type === 'userEmail' && errorMessage.content}
                    />
                  </div>
                </div>
              </div>
              <div className="change-email">
                {userData.userNewEmail ? (
                  <>
                    <div className="change-email-message">
                      {`${formatMessage({ id: 'User_9', defaultMessage: '현재' })} '${
                        userData.userNewEmail
                      }'${formatMessage({
                        id: 'User_10',
                        defaultMessage: '이 승인 대기중입니다.',
                      })}`}
                    </div>
                    <button
                      type="button"
                      className="bt-style-gleam"
                      onClick={async () => {
                        await dispatch(changeEmailResend());
                      }}
                    >
                      {formatMessage({
                        id: 'Resend_1',
                        defaultMessage: '재전송',
                      })}
                    </button>
                    <button
                      type="button"
                      className="bt-style-gleam bt-red"
                      onClick={async () => {
                        await dispatch(changeEmailCancel());
                      }}
                    >
                      {formatMessage({
                        id: 'Button_2',
                        defaultMessage: '취소',
                      })}
                    </button>
                  </>
                ) : (
                  <div className="change-email-message">
                    {formatMessage({
                      id: 'Email_19',
                      defaultMessage: '수정된 이메일은 별도의 확인 메일 승인 후 반영됩니다.',
                    })}
                  </div>
                )}
              </div>
              <div className="input-gap">
                <div className="config-row-option">
                  <div className="config-row-option-title">
                    <div className="config-class-title-wrap config-row-option-text">
                      {formatMessage({ id: 'Contact_1', defaultMessage: '연락처' })}
                    </div>
                  </div>
                  <div className="config-row-option-value">
                    <FormTextField
                      name="userPhone"
                      error={errors.userPhone}
                      defaultValue={userData.userPhone}
                      register={register}
                      validation={{
                        validate: {
                          userPhone: (value: any) => valid.userPhone(value),
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="input-gap">
                <div className="config-row-option">
                  <div className="config-row-option-title">
                    <div className="config-class-title-wrap config-row-option-text">
                      {formatMessage({ id: 'Service_8', defaultMessage: '서비스No' })}
                    </div>
                  </div>
                  <div className="config-row-option-value">
                    <FormTextField
                      name="serviceNo"
                      error={errors.serviceNo}
                      defaultValue={userData.serviceNo}
                      disabled="disabled"
                    />
                  </div>
                </div>
              </div>
              <div className="input-gap">
                <div className="config-row-option">
                  <div className="config-row-option-title">
                    <div className="config-class-title-wrap config-row-option-text">
                      {formatMessage({ id: 'Service_2', defaultMessage: '서비스명*' })}
                    </div>
                  </div>
                  <div className="config-row-option-value">
                    <FormTextField
                      name="serviceName"
                      error={errors.serviceName}
                      defaultValue={userData.serviceName}
                      register={register}
                      validation={{
                        validate: {
                          required: (value: any) => valid.required(value),
                          name: (value: any) => valid.name(value),
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="profile-button-box">
                <div className="profile-save-box">
                  <button key="cancel" type="button" onClick={onCancel}>
                    {formatMessage({ id: 'Button_2', defaultMessage: '취소' })}
                  </button>
                  <button key="ok" className="ok" type="button" onClick={handleSubmit(onOk)}>
                    {formatMessage({ id: 'Button_18', defaultMessage: '저장' })}
                    {loading && (
                      <span className="button-loading">
                        <LoadingOutlined />
                      </span>
                    )}
                  </button>
                </div>
                <div className="profile-passwd-box">
                  <button
                    key="pwChange"
                    type="button"
                    className="grey"
                    onClick={() => setOpenModal('changePasswd')}
                  >
                    {formatMessage({ id: 'Password_6', defaultMessage: '비밀번호 변경' })}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* 비밀번호 변경 모달 */}
      {openModal === 'changePasswd' && (
        <ModalTemplate
          className="change-passwd-modal modal-464"
          visible={openModal === 'changePasswd'}
          onOk={handleSubmit(changePasswd)}
          onCancel={() => {
            setOpenModal('');
            clearErrors();
            setErrorMessage({
              type: '',
              content: '',
            });
          }}
          centered
          loading={loading}
          title={formatMessage({ id: 'Password_6', defaultMessage: '비밀번호 변경' })}
          okText={formatMessage({ id: 'Button_1', defaultMessage: '변경' })}
          cancelText={formatMessage({ id: 'Button_2', defaultMessage: '취소' })}
        >
          <form autoComplete="off">
            <div className="text-field-title">
              {formatMessage({ id: 'Password_8', defaultMessage: '기존 비밀번호' })}
            </div>
            <FormTextField
              name="beforePassword"
              type="password"
              error={errors.beforePassword}
              register={register}
              validation={{
                validate: {
                  required: (value: any) => valid.required(value),
                },
              }}
              errorCheck={errorMessage.type === 'beforePassword'}
              errorText={errorMessage.type === 'beforePassword' && errorMessage.content}
            />
            <div className="text-field-title">
              {formatMessage({ id: 'Password_9', defaultMessage: '새 비밀번호' })}
            </div>
            <FormTextField
              name="userPasswd"
              type="password"
              error={errors.userPasswd}
              register={register}
              validation={{
                validate: {
                  required: (value: any) => valid.required(value),
                },
              }}
              errorCheck={errorMessage.type === 'userPasswd'}
              errorText={errorMessage.type === 'userPasswd' && errorMessage.content}
            />
            <div className="text-field-title">
              {formatMessage({ id: 'Password_10', defaultMessage: '새 비밀번호 확인' })}
            </div>
            <FormTextField
              name="userPasswdCheck"
              type="password"
              error={errors.userPasswdCheck}
              register={register}
              validation={{
                validate: {
                  required: (value: any) => valid.required(value),
                },
              }}
              errorCheck={errorMessage.type === 'userPasswdCheck'}
              errorText={errorMessage.type === 'userPasswdCheck' && errorMessage.content}
            />
          </form>
        </ModalTemplate>
      )}

      {/* 사용자 등록 의뢰 모달 */}
      {openModal === 'addUser' && (
        <ModalTemplate
          className="addUser-modal modal-464"
          visible={openModal === 'addUser'}
          title={formatMessage({ id: 'User_25', defaultMessage: '사용자 등록 의뢰' })}
          onOk={handleSubmit(onRequestAddUser)}
          onCancel={() => setOpenModal('')}
          okText={formatMessage({ id: 'User_25', defaultMessage: '사용자 등록 의뢰' })}
          cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
          loading={loading}
          greyButton
        >
          <div className="modal-explain-text">
            {formatMessage({ id: 'User_26', defaultMessage: '정말로 의뢰하시겠습니까?' })}
            <br />
            <br />
            {`※ ${formatMessage({
              id: 'User_27',
              defaultMessage: '본 등록 의뢰는 판매점이 실시합니다.',
            })} `}
            <div className='ml-20'>
              {formatMessage({
                id: 'User_28',
                defaultMessage: '계약자님은 조작하지 않도록 해주세요.',
              })}
            </div>
          </div>
        </ModalTemplate>
      )}

      {/* 탈퇴하기 모달 */}
      {openModal === 'leave' && (
        <ModalTemplate
          className="leave-modal modal-464"
          visible={openModal === 'leave'}
          title={formatMessage({ id: 'MyPage_5', defaultMessage: '탈퇴하기' })}
          onOk={handleSubmit(onLeaveService)}
          onCancel={() => setOpenModal('')}
          okText={
            Option.customer === 'saxa'
              ? ''
              : formatMessage({ id: 'MyPage_7', defaultMessage: '탈 퇴' })
          }
          cancelText={
            Option.customer === 'saxa'
              ? formatMessage({ id: 'Button_5', defaultMessage: '닫 기' })
              : formatMessage({ id: 'Button_12', defaultMessage: '취 소' })
          }
          loading={loading}
          greyButton
        >
          {Option.customer === 'saxa' ? (
            <div className="modal-explain-text">
              {formatMessage({
                id: 'MyPage_10',
                defaultMessage: '탈퇴를 원하시면 판매점에 문의해주세요.',
              })}
            </div>
          ) : (
            <>
              <div className="modal-explain-text">
                {formatMessage({ id: 'MyPage_6', defaultMessage: '정말 탈퇴하시겠습니까?' })}
              </div>

              <div className="modal-border-box">
                <form autoComplete="off" onSubmit={(e: any) => e.preventDefault()}>
                  <div className="content-item">
                    <div className="input-title">
                      {formatMessage({ id: 'Password_1', defaultMessage: '비밀번호' })}
                    </div>
                    <FormTextField
                      name="userPasswd"
                      type="password"
                      error={errors.userPasswd}
                      register={register}
                      arrowPosition="top"
                      validation={{
                        validate: {
                          required: (value: any) => valid.required(value),
                        },
                      }}
                    />
                  </div>
                </form>
              </div>
            </>
          )}
        </ModalTemplate>
      )}
    </div>
  );
}
